import ProtectedRoute from "../auth/ProtectedRoute";
import SideBarMenu from "../../components/SideBarMenu";
import staticsLogos1 from "../../components/staticsLogos/1.png";
import staticsLogos2 from "../../components/staticsLogos/2.png";
import staticsLogos3 from "../../components/staticsLogos/3.png";
import staticsLogos4 from "../../components/staticsLogos/4.png";

import { usePlacesQuery } from "../../hooks/place/usePlacesQuery";
import { usePartnersQuery } from "../../hooks/Partners/usePartnersQuery";
import { useUserQuery } from "../../hooks/users/useUserQuery";

import { Link } from "react-router-dom";
import AdminRoute from "../auth/RestrictedRoute";

import { useAuthContext } from "../../context/auth";
import HeaderMenu from "../../components/HeaderMenu";
import { useBookingQuery } from "../../hooks/bookings/useBookingQuery";
import { formatLargeNumber } from "../../helpers/general";
import { useAuth } from "../../hooks/useAuth";
import Sidebar from "../../components/Sidebar";

export default function Home() {
  const { totalUsers } = useUserQuery();

  const { user } = useAuthContext();

  const { totalPlaces } = usePlacesQuery();

  const { Partners } = usePartnersQuery();

  const { totalCount } = useBookingQuery();
  const { isAdmin } = useAuth();

  return (
    <ProtectedRoute route="Auth">
      {/* <AdminRoute> */}
      <HeaderMenu />
      <div className="d-flex">
        <Sidebar className="d-lg-block d-none" />
        {/* <SideBarMenu className="px-3 py-2 vh-100 position-sticky top-0 left-0 sidebar d-lg-block d-none" /> */}
        <div className="w-100 bg-white ">
          <div className=" text-white py-3 px-5 bg-header d-flex align-items-center gap-4 welcome-banner">
            <div className="rounded-circle border border-2 border-danger overflow-hidden bg-white">
              <img
                src={user?.profilePicture as string}
                alt=""
                width={70}
                height={70}
              />
            </div>
            <div>
              {" "}
              <p className="fs-3 fw-bold mb-0 text-capitalize">
                Bonjour {user?.fullName} !
              </p>
              <p>
                Bienvenue à tous ! Gérez vos tâches et votre travail quotidien
                ici.
              </p>
            </div>
          </div>

          <div className="container pt-5 pb-5">
            <h5 className="fs-5 mb-3 ps-5">Statistiques</h5>
            <div className="row row-cols-md-2 cart">
              {isAdmin && (
                <>
                  <div className="border-0 position-relative carte">
                    <Link to={"/users"}>
                      <img src={staticsLogos2} alt="" className="w-100" />
                    </Link>
                    <div className="card-content">
                      <p className="m-0 fs-2 text-white fw-bold">
                        {formatLargeNumber(totalUsers)}
                      </p>
                      <p className="fs-4">Utilisateurs</p>
                    </div>
                  </div>

                  <div className="border-0 position-relative carte pt-2">
                    <Link to={"/places"}>
                      <img src={staticsLogos1} alt="" className="w-100" />
                    </Link>
                    <div className="card-content">
                      <p className="m-0 fs-2 text-white fw-bold">
                        {formatLargeNumber(totalPlaces)}
                      </p>
                      <p className="fs-4">Établissement</p>
                    </div>
                  </div>

                  <div className="border-0 position-relative carte">
                    <Link to={"/partners"}>
                      <img src={staticsLogos4} alt="" className="w-100" />
                    </Link>
                    <div className="card-content">
                      <p className="m-0 fs-2 text-white fw-bold">
                        {formatLargeNumber(Partners?.length)}
                      </p>
                      <p className="fs-4">Partenaires</p>
                    </div>
                  </div>
                </>
              )}
              <div className="border-0 position-relative carte">
                <Link to={"/bookings"}>
                  <img src={staticsLogos3} alt="" className="w-100" />
                </Link>
                <div className="card-content">
                  <p className="m-0 fs-2 text-white fw-bold">
                    {formatLargeNumber(totalCount)}
                  </p>
                  <p className="fs-4">Réservations</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </AdminRoute> */}
    </ProtectedRoute>
  );
}
