import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CNavItem
} from "@coreui/react";
import { useAuth } from "../hooks/useAuth";
import { SidebarIcon } from "./icons/SidebarIcons";
import { useAuthContext } from "../context/auth";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDebounce } from "usehooks-ts";
import { TagIcon } from "@heroicons/react/24/solid";

const createDashboardRoutes = (user: User | null, isAdmin: boolean) => [
  {
    label: "Accueil",
    path: "/home",
    icon: SidebarIcon.HomeSvg,
    show: true,
    isActive: (pathname: string) => pathname.includes("/home")
  },
  {
    label: "Établissements",
    path: "/places",
    icon: SidebarIcon.PlaceSVG,
    show: true,
    isActive: (pathname: string) => pathname.includes("/places")
  },
  {
    label: "Utilisateurs",
    path: "/users",
    icon: SidebarIcon.UsersSvg,
    show: isAdmin,
    isActive: (pathname: string) => pathname.includes("/users")
  },
  {
    label: "Réservations",
    path: "/bookings",
    icon: SidebarIcon.BookingSvg,
    show: user?.adminForPlace?.supportReservations || isAdmin,
    isActive: (pathname: string) => pathname.includes("/bookings")
  },
  {
    label: "Evénements",
    path: "/events",
    icon: SidebarIcon.EventSvg,
    show: true,
    isActive: (pathname: string) => pathname.includes("/events")
  },
  {
    label: "Offres",
    path: "/offers",
    icon: SidebarIcon.OffersSvg,
    show: true,
    isActive: (pathname: string) => pathname.includes("/offers")
  },
  {
    label: "Packs",
    path: "/packs",
    icon: SidebarIcon.PacksSvg,
    show: true,
    isActive: (pathname: string) => pathname.includes("/packs")
  },
  {
    label: "Catégories",
    path: "/categories",
    icon: SidebarIcon.CategoriesSvg,
    show: isAdmin,
    isActive: (pathname: string) => pathname.includes("/categories")
  },
  {
    label: "Catégories de services",
    path: "/service-categories",
    icon: SidebarIcon.ServiceCategoriesSVG,
    show: isAdmin,
    isActive: (pathname: string) => pathname.includes("/service-categories")
  },
  {
    label: "Promo codes",
    path: "/promo-codes",
    icon: TagIcon,
    show: true,
    isActive: (pathname: string) => pathname.includes("/promo-codes")
  },
  {
    label: "Notifications",
    path: "/notifications",
    icon: SidebarIcon.NotificationsSvg,
    show: isAdmin,
    isActive: (pathname: string) => pathname.includes("/notifications")
  },
  {
    label: "Messages de WhatsApp",
    path: "/whatsapp-messages",
    icon: SidebarIcon.MessagesWhatsappSvg,
    show: isAdmin,
    isActive: (pathname: string) => pathname.includes("/whatsapp-messages")
  },
  {
    label: "Partenaires",
    path: "/partners",
    icon: SidebarIcon.PartnersSvg,
    show: isAdmin,
    isActive: (pathname: string) => pathname.includes("/partners")
  },
  {
    label: "Villes",
    path: "/cities",
    icon: SidebarIcon.CitiesSvg,
    show: isAdmin,
    isActive: (pathname: string) => pathname.includes("/cities")
  },
  {
    label: "Inspecteurs",
    path: "/inspectors",
    icon: SidebarIcon.InspectorsSvg,
    show: isAdmin,
    isActive: (pathname: string) => pathname.includes("/inspectors")
  },
  {
    label: "Newsletter",
    path: "/newsletter",
    icon: SidebarIcon.NewsletterSvg,
    show: isAdmin,
    isActive: (pathname: string) => pathname.includes("/newsletter")
  },
  {
    label: "Variables",
    path: "/variables",
    icon: SidebarIcon.ArrowsRightLeftIcon,
    show: isAdmin,
    isActive: (pathname: string) => pathname.includes("/variables")
  },
  {
    label: "Paiements",
    path: "/payouts",
    icon: SidebarIcon.PayoutsSvg,
    show: true,
    isActive: (pathname: string) => pathname.includes("/payouts")
  },
  {
    label: "Traductions",
    path: "/translations",
    icon: SidebarIcon.TranslationsSvg,
    show: isAdmin,
    isActive: (pathname: string) => pathname.includes("/translations")
  }
];

const createVenezTaxiRoutes = (user: User | null, isAdmin: boolean) => [
  {
    label: "Chauffeurs",
    path: "/drivers",
    icon: SidebarIcon.DriversSvg,
    show: isAdmin,
    isActive: (pathname: string) => pathname.includes("/drivers")
  }
];

interface SidebarProps {
  onClose?: () => void;
  className?: string;
}

enum Sidebars {
  ADMIN = "ADMIN",
  PLACE_ADMIN = "PLACE_ADMIN"
}

export default function Sidebar(props: SidebarProps) {
  const { onClose, className = "" } = props;

  const { isAdmin } = useAuth();
  const { user } = useAuthContext();
  const location = useLocation();
  const [activeAccordion, setActiveAccordion] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState("");
  const searchQuery = useDebounce(searchTerm, 300);

  const adminRoutes = createDashboardRoutes(user, isAdmin);

  const menus = useMemo(
    () => [
      { title: "Tableau de bord", menus: adminRoutes },
      { title: "Venez taxi", menus: createVenezTaxiRoutes(user, isAdmin) }
    ],
    [adminRoutes, user, isAdmin]
  );

  const filteredMenus = useMemo(() => {
    if (!searchQuery) return menus;
    return menus
      .map(section => ({
        ...section,
        menus: section.menus.filter(menu =>
          menu.label.toLowerCase().includes(searchQuery.toLowerCase())
        )
      }))
      .filter(section => section.menus.length > 0);
  }, [menus, searchQuery]);

  // Determine which accordion should be open based on the current route
  useEffect(() => {
    const updateActiveAccordion = () => {
      if (searchQuery) {
        const matchIndex = filteredMenus.findIndex(
          section => section.menus.length > 0
        );
        setActiveAccordion(matchIndex !== -1 ? matchIndex : 0);
      } else {
        const currentMenuIndex = menus.findIndex(menu =>
          menu.menus.some(item => item.isActive(location.pathname))
        );
        setActiveAccordion(currentMenuIndex !== -1 ? currentMenuIndex : 0);
      }
    };

    updateActiveAccordion();
  }, [location.pathname, menus, filteredMenus, searchQuery]);

  const activeSidebar = isAdmin ? Sidebars.ADMIN : Sidebars.PLACE_ADMIN;

  const SidebarMenu = {
    [Sidebars.ADMIN]: () => (
      <div>
        <div className="px-2 mb-3">
          <input
            type="search"
            className="custom-input"
            name="search"
            placeholder="rechercher dans le menu"
            value={searchTerm}
            autoFocus
            onChange={e => {
              setSearchTerm(e.target.value);
            }}
          />
        </div>
        <CAccordion activeItemKey={activeAccordion} flush>
          {filteredMenus.map((accordion, index) => (
            <CAccordionItem itemKey={index} key={index}>
              <CAccordionHeader>{accordion.title}</CAccordionHeader>
              <CAccordionBody className="nav-menu">
                {accordion.menus.map(menu =>
                  menu.show ? (
                    <CNavItem
                      onClick={onClose}
                      href={menu.path}
                      className={`list-unstyled ${
                        menu.isActive(location.pathname)
                          ? "text-danger"
                          : "text-black"
                      }`}
                      key={menu.label}
                    >
                      <Link
                        to={menu.path}
                        className={`${
                          menu.isActive(location.pathname)
                            ? "text-danger"
                            : "text-black"
                        } w-full`}
                      >
                        <div className="d-flex w-full fw-medium gap-3 sidbar-link">
                          {React.createElement(menu.icon, {
                            className: `${
                              ["variables", "promo codes"].includes(
                                menu.label.toLowerCase()
                              )
                                ? "w-5 h-5"
                                : ""
                            } ${
                              menu.isActive(location.pathname)
                                ? "fill-danger"
                                : "fill-black"
                            }`
                          })}
                          {menu.label}
                        </div>
                      </Link>
                    </CNavItem>
                  ) : null
                )}
              </CAccordionBody>
            </CAccordionItem>
          ))}
        </CAccordion>
      </div>
    ),
    [Sidebars.PLACE_ADMIN]: () => (
      <>
        {adminRoutes.map(menu =>
          menu.show ? (
            <CNavItem
              onClick={onClose}
              href={menu.path}
              className={`list-unstyled ${
                menu.isActive(location.pathname) ? "text-danger" : "text-black"
              }`}
              key={menu.label}
            >
              <Link
                to={menu.path}
                className={`${
                  menu.isActive(location.pathname)
                    ? "text-danger"
                    : "text-black"
                } w-full`}
              >
                <div className="d-flex w-full fw-medium gap-3 sidbar-link">
                  {React.createElement(menu.icon, {
                    className: `${
                      menu.label.toLowerCase() === "variables" ? "w-5 h-5" : ""
                    } ${
                      menu.isActive(location.pathname)
                        ? "fill-danger"
                        : "fill-black"
                    }`
                  })}
                  {menu.label}
                </div>
              </Link>
            </CNavItem>
          ) : null
        )}
      </>
    )
  }[activeSidebar];

  return (
    <div className={`sidebar px-1 py-3 ${className}`}>
      <SidebarMenu />
    </div>
  );
}
