import React from "react";
import SideBarMenu from "../SideBarMenu";
import { Outlet, useLocation } from "react-router-dom";
import ProtectedRoute from "../../pages/auth/ProtectedRoute";
import HeaderMenu from "../HeaderMenu";
import { useAuth } from "../../hooks/useAuth";
import Sidebar from "../Sidebar";

export default function AppLayout() {
  const location = useLocation();
  const { isAdmin } = useAuth();

  const removePadding =
    location.pathname?.includes("planner") ||
    (!isAdmin && location.pathname === "/places");
  return (
    <ProtectedRoute route="Auth">
      <HeaderMenu></HeaderMenu>

      <main className="d-flex">
        {/* <SideBarMenu className="px-3 py-2 vh-100 position-sticky top-0 left-0 sidebar d-lg-block d-none" /> */}
        <Sidebar className="d-lg-block d-none" />
        <div
          // table-container
          className={`w-100 bg-gray py-4 px-3  ${removePadding &&
            "floor-planner"}`}
        >
          <Outlet />
        </div>
      </main>
    </ProtectedRoute>
  );
}
