import {
  CNav,
  CNavItem,
  CNavLink,
  CSpinner,
  CTabContent,
  CTabPane
} from "@coreui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useEffect, useMemo, useReducer, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import BoxWrapper from "../../components/BoxWrapper";
import DeleteIcon from "../../components/icons/DeleteIcon";
import Pagination from "../../components/Pagination";
import RenderTable from "../../components/RenderTable";
import { colors } from "../../config/theme";
import {
  bulkDeleteReducer,
  bulkDeleteState
} from "../../context/bulkDeleteReducer";
import AdminRoute from "../auth/RestrictedRoute";
import PlaceTranslationsTable from "./Places/PlacesTranslationsTable";
import { useTranslationsQuery } from "../../hooks/Translations/useTranslationQuery";
import { general } from "../../locales/general";
import CategoryTranslationsTable from "./Categories/CategoriesTranslationsTable";
import EventTranslationsTable from "./Events/EventsTranslationsTable";
import PackTranslationsTable from "./Packs/PacksTranslationsTable";
import ServiceCategoryTranslationsTable from "./ServiceCategories/ServiceCategoriesTranslationsTable";
import ServiceTranslationsTable from "./Services/ServicesTranslationsTable";
import SpecialTranslationsTable from "./Specials/SpecialsTranslationsTable";
import { pluralize } from "../../helpers/general";
import ServiceSubCategoryTranslationsTable from "./serviceSubCategory/AllSubCategories";

const PAGE_SIZE = 10;

const TAB_HASHES = [
  "#place",
  "#event",
  "#special",
  "#category",
  "#service",
  "#service-category",
  "#service-sub-category",
  "#pack"
];

export default function Translations() {
  // const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [visible, setVisible] = useState(false);
  const [target, setTarget] = useState("place");
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const index = TAB_HASHES.indexOf(hash);
      index > 0 ? setActiveTab(index) : setActiveTab(0);
      setTarget(hash.replace("#", ""));
    }
    setSearchQuery("");
  }, [hash]);

  const {
    translations,
    totalPages,
    totalCount,
    fetchingTranslations,
    deleteTranslation,
    mutating,
    currentPage,
    searchHandler,
    setCurrentPage
  } = useTranslationsQuery(target);

  const filteredTranslations = useMemo(() => {
    const filtered = translations?.filter(
      (translation: any) =>
        translation?.name?.toLowerCase()?.includes(searchQuery) ||
        translation?.title?.toLowerCase()?.includes(searchQuery) ||
        translation?.label?.toLowerCase()?.includes(searchQuery)
    );
    return filtered;
  }, [translations, searchQuery]);

  const [placeTranslationsToBeDeleted, dispatch] = useReducer(
    bulkDeleteReducer,
    bulkDeleteState
  );

  const handleBulkDelete = () => {
    placeTranslationsToBeDeleted.ids.forEach(id => {
      if (!id) return;
      deleteTranslation(id);
    });
  };
  const PartnerSVG = () => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7044 3.51898C10.034 3.51898 9.46373 3.9848 9.30365 4.61265H14.6863C14.5263 3.9848 13.956 3.51898 13.2856 3.51898H10.7044ZM16.2071 4.61264H18.1881C20.2891 4.61264 22 6.34428 22 8.47085C22 8.47085 21.94 9.3711 21.92 10.6248C21.918 10.724 21.8699 10.8212 21.7909 10.88C21.3097 11.2354 20.8694 11.5291 20.8294 11.5493C19.1686 12.6632 17.2386 13.447 15.1826 13.8369C15.0485 13.8632 14.9165 13.7934 14.8484 13.6739C14.2721 12.6754 13.1956 12.0253 11.995 12.0253C10.8024 12.0253 9.71586 12.6683 9.12256 13.6678C9.05353 13.7853 8.92346 13.8531 8.7904 13.8278C6.75138 13.4369 4.82141 12.6541 3.17059 11.5594L2.21011 10.8911C2.13007 10.8405 2.08004 10.7493 2.08004 10.6481C2.05003 10.1316 2 8.47085 2 8.47085C2 6.34428 3.71086 4.61264 5.81191 4.61264H7.78289C7.97299 3.1443 9.2036 2 10.7044 2H13.2856C14.7864 2 16.017 3.1443 16.2071 4.61264ZM21.6598 12.8152L21.6198 12.8355C19.5988 14.1924 17.1676 15.0937 14.6163 15.4684C14.2561 15.519 13.8959 15.2861 13.7959 14.9216C13.5758 14.0912 12.8654 13.5443 12.015 13.5443H12.005H11.985C11.1346 13.5443 10.4242 14.0912 10.2041 14.9216C10.1041 15.2861 9.74387 15.519 9.38369 15.4684C6.83242 15.0937 4.4012 14.1924 2.38019 12.8355C2.37019 12.8254 2.27014 12.7646 2.1901 12.8152C2.10005 12.8659 2.10005 12.9874 2.10005 12.9874L2.17009 18.1519C2.17009 20.2785 3.87094 22 5.97199 22H18.018C20.1191 22 21.8199 20.2785 21.8199 18.1519L21.9 12.9874C21.9 12.9874 21.9 12.8659 21.8099 12.8152C21.7599 12.7849 21.6999 12.795 21.6598 12.8152ZM12.7454 17.0583C12.7454 17.4836 12.4152 17.8177 11.995 17.8177C11.5848 17.8177 11.2446 17.4836 11.2446 17.0583V15.7519C11.2446 15.3367 11.5848 14.9924 11.995 14.9924C12.4152 14.9924 12.7454 15.3367 12.7454 15.7519V17.0583Z"
        />{" "}
      </svg>
    );
  };

  // const totalPages = Math.ceil(totalCount / PAGE_SIZE);
  return (
    <AdminRoute>
      <h5 className="fs-4 fw-bold mb-2">Traductions</h5>
      <BoxWrapper>
        <CNav variant="tabs" className="mb-2">
          <CNavItem
            href="#place"
            active={activeTab === 0}
            onClick={() => {
              setTarget("place");
              setActiveTab(0);
              setCurrentPage(1);
            }}
          >
            <CNavLink>Établissements</CNavLink>
          </CNavItem>
          <CNavItem
            href="#event"
            active={activeTab === 1}
            onClick={() => {
              setTarget("event");
              setActiveTab(1);
              setCurrentPage(1);
            }}
          >
            <CNavLink>Évènements</CNavLink>
          </CNavItem>
          <CNavItem
            href="#special"
            active={activeTab === 2}
            onClick={() => {
              setTarget("special");
              setActiveTab(2);
              setCurrentPage(1);
            }}
          >
            <CNavLink>Offres</CNavLink>
          </CNavItem>
          <CNavItem
            href="#category"
            active={activeTab === 3}
            onClick={() => {
              setTarget("category");
              setActiveTab(3);
              setCurrentPage(1);
            }}
          >
            <CNavLink>Catégories</CNavLink>
          </CNavItem>
          <CNavItem
            href="#service"
            active={activeTab === 4}
            onClick={() => {
              setTarget("service");
              setActiveTab(4);
              setCurrentPage(1);
            }}
          >
            <CNavLink>Services</CNavLink>
          </CNavItem>
          <CNavItem
            href="#service-category"
            active={activeTab === 5}
            onClick={() => {
              setTarget("service-category");
              setActiveTab(5);
              setCurrentPage(1);
            }}
          >
            <CNavLink>Les catégories des services</CNavLink>
          </CNavItem>
          <CNavItem
            href="#service-sub-category"
            active={activeTab === 6}
            onClick={() => {
              setTarget("place");
              setActiveTab(0);
              setCurrentPage(1);
            }}
          >
            <CNavLink>Sous-catégorie de service</CNavLink>
          </CNavItem>
          <CNavItem
            href="#pack"
            active={activeTab === 7}
            onClick={() => {
              setTarget("pack");
              setActiveTab(6);
              setCurrentPage(1);
            }}
          >
            <CNavLink>Packs</CNavLink>
          </CNavItem>
        </CNav>

        <CTabContent>
          <CTabPane visible={activeTab === 0}>
            <div
              className="d-flex  justify-content-between align-items-baseline before-table"
              id="places"
            >
              <div className="d-flex align-items-center gap-2 p-3 delete-add">
                <Link
                  to="/place-translations/new"
                  className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary text-pre"
                >
                  + Ajouter une traduction
                </Link>

                <button
                  disabled={
                    mutating || placeTranslationsToBeDeleted?.ids.length === 0
                  }
                  onClick={handleBulkDelete}
                  className="outline-none  btn btn-outline-danger rounded-pill text-danger d-flex align-items-center gap-2 justify-content-center shadow-primary"
                >
                  {!mutating ? (
                    <>
                      <DeleteIcon fill={colors.primary} /> Supprimer
                    </>
                  ) : (
                    <>
                      <CSpinner size="sm" /> {general.fr.wait}
                    </>
                  )}
                </button>
              </div>
              <div className="search-form">
                <div className=" input-field">
                  <MagnifyingGlassIcon color="gray" width={20} />
                  <input
                    type="text"
                    className="w-50 border-0  outline-none rounded-sm p-1 text-gray fs-6"
                    placeholder="Rechercher"
                    value={searchQuery}
                    onChange={e => {
                      searchHandler(e.target.value.toLowerCase());
                      setSearchQuery(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div
                className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3"
                style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
              >
                <PartnerSVG />
                <p className="fs-5 fw-bold m-0  text-white text-pre">
                  {" "}
                  {totalCount} {totalCount > 1 ? "Traductions" : "Traduction"}
                </p>
              </div>
            </div>

            <RenderTable
              loading={fetchingTranslations}
              render={() =>
                translations?.length > 0 ? (
                  <PlaceTranslationsTable
                    placeTranslations={translations as PlaceTranslation[]}
                    placeTranslationsToBeDeleted={dispatch}
                  />
                ) : (
                  <p className="text-center fs-5 my-2">{general.fr.noData}</p>
                )
              }
            />
          </CTabPane>
          <CTabPane visible={activeTab === 1}>
            <div
              className="d-flex  justify-content-between align-items-baseline before-table"
              id="events"
            >
              <div className="d-flex align-items-center gap-2 p-3 delete-add">
                <Link
                  to="/event-translations/new"
                  className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary text-pre"
                >
                  + Ajouter une traduction
                </Link>

                <button
                  disabled={
                    mutating || placeTranslationsToBeDeleted?.ids.length === 0
                  }
                  onClick={handleBulkDelete}
                  className="outline-none  btn btn-outline-danger rounded-pill text-danger d-flex align-items-center gap-2 justify-content-center shadow-primary"
                >
                  {!mutating ? (
                    <>
                      <DeleteIcon fill={colors.primary} /> Supprimer
                    </>
                  ) : (
                    <>
                      <CSpinner size="sm" /> {general.fr.wait}
                    </>
                  )}
                </button>
              </div>
              <div className="search-form">
                <div className=" input-field">
                  <MagnifyingGlassIcon color="gray" width={20} />
                  <input
                    type="text"
                    className="w-50 border-0  outline-none rounded-sm p-1 text-gray fs-6"
                    placeholder="Rechercher"
                    // onChange={e =>
                    //   setSearchQuery(e?.target?.value?.toLowerCase())
                    // }
                    value={searchQuery}
                    onChange={e => {
                      searchHandler(e.target.value.toLowerCase());
                      setSearchQuery(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div
                className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3"
                style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
              >
                <PartnerSVG />
                <p className="fs-5 fw-bold m-0  text-white text-pre">
                  {" "}
                  {totalCount} {totalCount > 1 ? "Traductions" : "Traduction"}
                </p>
              </div>
            </div>

            <RenderTable
              loading={fetchingTranslations}
              render={() =>
                translations?.length > 0 ? (
                  <EventTranslationsTable
                    eventTranslations={translations as EventTranslation[]}
                    eventTranslationsToBeDeleted={dispatch}
                  />
                ) : (
                  <p className="text-center fs-5 my-2">{general.fr.noData}</p>
                )
              }
            />
          </CTabPane>
          <CTabPane visible={activeTab === 2}>
            <div
              className="d-flex  justify-content-between align-items-baseline before-table"
              id="specials"
            >
              <div className="d-flex align-items-center gap-2 p-3 delete-add">
                <Link
                  to="/special-translations/new"
                  className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary text-pre"
                >
                  + Ajouter une traduction
                </Link>
                {/* <CModal
                  size="lg"
                  alignment="center"
                  visible={visible}
                  onClose={() => setVisible(false)}
                >
                  <NewPlaceTranslation
                    onAccept={() => { }}
                    onClose={() => setVisible(false)}
                  />
                </CModal> */}

                <button
                  disabled={
                    mutating || placeTranslationsToBeDeleted?.ids.length === 0
                  }
                  onClick={handleBulkDelete}
                  className="outline-none  btn btn-outline-danger rounded-pill text-danger d-flex align-items-center gap-2 justify-content-center shadow-primary"
                >
                  {!mutating ? (
                    <>
                      <DeleteIcon fill={colors.primary} /> Supprimer
                    </>
                  ) : (
                    <>
                      <CSpinner size="sm" /> {general.fr.wait}
                    </>
                  )}
                </button>
              </div>
              <div className="search-form">
                <div className=" input-field">
                  <MagnifyingGlassIcon color="gray" width={20} />
                  <input
                    type="text"
                    className="w-50 border-0  outline-none rounded-sm p-1 text-gray fs-6"
                    placeholder="Rechercher"
                    // onChange={e =>
                    //   setSearchQuery(e?.target?.value?.toLowerCase())
                    // }
                    value={searchQuery}
                    onChange={e => {
                      searchHandler(e.target.value.toLowerCase());
                      setSearchQuery(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div
                className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3"
                style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
              >
                <PartnerSVG />
                <p className="fs-5 fw-bold m-0  text-white text-pre">
                  {" "}
                  {totalCount} {totalCount > 1 ? "Traductions" : "Traduction"}
                </p>
              </div>
            </div>

            <RenderTable
              loading={fetchingTranslations}
              render={() =>
                translations?.length > 0 ? (
                  <SpecialTranslationsTable
                    specialTranslations={translations as SpecialTranslation[]}
                    specialTranslationsToBeDeleted={dispatch}
                  />
                ) : (
                  <p className="text-center fs-5 my-2">{general.fr.noData}</p>
                )
              }
            />
          </CTabPane>
          <CTabPane visible={activeTab === 3}>
            <div
              className="d-flex  justify-content-between align-items-baseline before-table"
              id="categories"
            >
              <div className="d-flex align-items-center gap-2 p-3 delete-add">
                <Link
                  to="/category-translations/new"
                  className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary text-pre"
                >
                  + Ajouter une traduction
                </Link>
                {/* <CModal
                  size="lg"
                  alignment="center"
                  visible={visible}
                  onClose={() => setVisible(false)}
                >
                  <NewPlaceTranslation
                    onAccept={() => { }}
                    onClose={() => setVisible(false)}
                  />
                </CModal> */}

                <button
                  disabled={
                    mutating || placeTranslationsToBeDeleted?.ids.length === 0
                  }
                  onClick={handleBulkDelete}
                  className="outline-none  btn btn-outline-danger rounded-pill text-danger d-flex align-items-center gap-2 justify-content-center shadow-primary"
                >
                  {!mutating ? (
                    <>
                      <DeleteIcon fill={colors.primary} /> Supprimer
                    </>
                  ) : (
                    <>
                      <CSpinner size="sm" /> {general.fr.wait}
                    </>
                  )}
                </button>
              </div>
              <div className="search-form">
                <div className=" input-field">
                  <MagnifyingGlassIcon color="gray" width={20} />
                  <input
                    type="text"
                    className="w-50 border-0  outline-none rounded-sm p-1 text-gray fs-6"
                    placeholder="Rechercher"
                    // onChange={e =>
                    //   setSearchQuery(e?.target?.value?.toLowerCase())
                    // }
                    value={searchQuery}
                    onChange={e => {
                      searchHandler(e.target.value.toLowerCase());
                      setSearchQuery(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div
                className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3"
                style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
              >
                <PartnerSVG />
                <p className="fs-5 fw-bold m-0  text-white text-pre">
                  {" "}
                  {totalCount} {totalCount > 1 ? "Traductions" : "Traduction"}
                </p>
              </div>
            </div>

            <RenderTable
              loading={fetchingTranslations}
              render={() =>
                translations?.length > 0 ? (
                  <CategoryTranslationsTable
                    categoryTranslations={translations as CategoryTranslation[]}
                    categoryTranslationsToBeDeleted={dispatch}
                  />
                ) : (
                  <p className="text-center fs-5 my-2">{general.fr.noData}</p>
                )
              }
            />
          </CTabPane>

          <CTabPane visible={activeTab === 4}>
            <div
              className="d-flex  justify-content-between align-items-baseline before-table"
              id="service"
            >
              <div className="d-flex align-items-center gap-2 p-3 delete-add">
                <Link
                  to="/service-translations/new"
                  className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary text-pre"
                >
                  + Ajouter une traduction
                </Link>
                {/* <CModal
                  size="lg"
                  alignment="center"
                  visible={visible}
                  onClose={() => setVisible(false)}
                >
                  <NewPlaceTranslation
                    onAccept={() => { }}
                    onClose={() => setVisible(false)}
                  />
                </CModal> */}

                <button
                  disabled={
                    mutating || placeTranslationsToBeDeleted?.ids.length === 0
                  }
                  onClick={handleBulkDelete}
                  className="outline-none  btn btn-outline-danger rounded-pill text-danger d-flex align-items-center gap-2 justify-content-center shadow-primary"
                >
                  {!mutating ? (
                    <>
                      <DeleteIcon fill={colors.primary} /> Supprimer
                    </>
                  ) : (
                    <>
                      <CSpinner size="sm" /> {general.fr.wait}
                    </>
                  )}
                </button>
              </div>
              <div className="search-form">
                <div className=" input-field">
                  <MagnifyingGlassIcon color="gray" width={20} />
                  <input
                    type="text"
                    className="w-50 border-0  outline-none rounded-sm p-1 text-gray fs-6"
                    placeholder="Rechercher"
                    // onChange={e =>
                    //   setSearchQuery(e?.target?.value?.toLowerCase())
                    // }
                    value={searchQuery}
                    onChange={e => {
                      searchHandler(e.target.value.toLowerCase());
                      setSearchQuery(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div
                className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3"
                style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
              >
                <PartnerSVG />
                <p className="fs-5 fw-bold m-0  text-white text-pre">
                  {" "}
                  {totalCount} {totalCount > 1 ? "Traductions" : "Traduction"}
                </p>
              </div>
            </div>

            <RenderTable
              loading={fetchingTranslations}
              render={() =>
                translations?.length > 0 ? (
                  <ServiceTranslationsTable
                    serviceTranslations={translations as ServiceTranslation[]}
                    serviceTranslationsToBeDeleted={dispatch}
                  />
                ) : (
                  <p className="text-center fs-5 my-2">{general.fr.noData}</p>
                )
              }
            />
          </CTabPane>
          <CTabPane visible={activeTab === 5}>
            <div
              className="d-flex  justify-content-between align-items-baseline before-table"
              id="serviceCategories"
            >
              <div className="d-flex align-items-center gap-2 p-3 delete-add">
                <Link
                  to="/service-category-translations/new"
                  className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary text-pre"
                >
                  + Ajouter une traduction
                </Link>
                {/* <CModal
                  size="lg"
                  alignment="center"
                  visible={visible}
                  onClose={() => setVisible(false)}
                >
                  <NewPlaceTranslation
                    onAccept={() => { }}
                    onClose={() => setVisible(false)}
                  />
                </CModal> */}

                <button
                  disabled={
                    mutating || placeTranslationsToBeDeleted?.ids.length === 0
                  }
                  onClick={handleBulkDelete}
                  className="outline-none  btn btn-outline-danger rounded-pill text-danger d-flex align-items-center gap-2 justify-content-center shadow-primary"
                >
                  {!mutating ? (
                    <>
                      <DeleteIcon fill={colors.primary} /> Supprimer
                    </>
                  ) : (
                    <>
                      <CSpinner size="sm" /> {general.fr.wait}
                    </>
                  )}
                </button>
              </div>
              <div className="search-form">
                <div className=" input-field">
                  <MagnifyingGlassIcon color="gray" width={20} />
                  <input
                    type="text"
                    className="w-50 border-0  outline-none rounded-sm p-1 text-gray fs-6"
                    placeholder="Rechercher"
                    // onChange={e =>
                    //   setSearchQuery(e?.target?.value?.toLowerCase())
                    // }
                    value={searchQuery}
                    onChange={e => {
                      searchHandler(e.target.value.toLowerCase());
                      setSearchQuery(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div
                className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3"
                style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
              >
                <PartnerSVG />
                <p className="fs-5 fw-bold m-0  text-white text-pre">
                  {" "}
                  {totalCount} {totalCount > 1 ? "Traductions" : "Traduction"}
                </p>
              </div>
            </div>

            <RenderTable
              loading={fetchingTranslations}
              render={() =>
                translations?.length > 0 ? (
                  <ServiceCategoryTranslationsTable
                    serviceCategoryTranslations={
                      translations as ServiceCategoryTranslation[]
                    }
                    serviceCategoryTranslationsToBeDeleted={dispatch}
                  />
                ) : (
                  <p className="text-center fs-5 my-2">{general.fr.noData}</p>
                )
              }
            />
          </CTabPane>
          <CTabPane visible={activeTab === 6}>
            <div
              className="d-flex  justify-content-between align-items-baseline before-table"
              id="serviceSubCategories"
            >
              <div className="d-flex align-items-center gap-2 p-3 delete-add">
                <Link
                  to="/service-sub-category-translations/new"
                  className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary text-pre"
                >
                  + Ajouter une traduction
                </Link>

                <button
                  disabled={
                    mutating || placeTranslationsToBeDeleted?.ids.length === 0
                  }
                  onClick={handleBulkDelete}
                  className="outline-none  btn btn-outline-danger rounded-pill text-danger d-flex align-items-center gap-2 justify-content-center shadow-primary"
                >
                  {!mutating ? (
                    <>
                      <DeleteIcon fill={colors.primary} /> Supprimer
                    </>
                  ) : (
                    <>
                      <CSpinner size="sm" /> {general.fr.wait}
                    </>
                  )}
                </button>
              </div>
              <div className="search-form">
                <div className=" input-field">
                  <MagnifyingGlassIcon color="gray" width={20} />
                  <input
                    type="text"
                    className="w-50 border-0  outline-none rounded-sm p-1 text-gray fs-6"
                    placeholder="Rechercher"
                    // onChange={e =>
                    //   setSearchQuery(e?.target?.value?.toLowerCase())
                    // }
                    value={searchQuery}
                    onChange={e => {
                      searchHandler(e.target.value.toLowerCase());
                      setSearchQuery(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div
                className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3"
                style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
              >
                <PartnerSVG />
                <p className="fs-5 fw-bold m-0  text-white text-pre">
                  {pluralize("Traduction", totalCount)}
                </p>
              </div>
            </div>

            <RenderTable
              loading={fetchingTranslations}
              render={() =>
                translations?.length > 0 ? (
                  <ServiceSubCategoryTranslationsTable
                    serviceCategoryTranslations={
                      translations as ServiceSubCategoryTranslation[]
                    }
                    serviceCategoryTranslationsToBeDeleted={dispatch}
                  />
                ) : (
                  <p className="text-center fs-5 my-2">{general.fr.noData}</p>
                )
              }
            />
          </CTabPane>
          <CTabPane visible={activeTab === 7}>
            <div
              className="d-flex  justify-content-between align-items-baseline before-table"
              id="packs"
            >
              <div className="d-flex align-items-center gap-2 p-3 delete-add">
                <Link
                  to="/pack-translations/new"
                  className="outline-none border-0 btn btn-primary rounded-pill text-white d-flex align-items-center gap-2 justify-content-center shadow-secondary text-pre"
                >
                  + Ajouter une traduction
                </Link>
                {/* <CModal
                  size="lg"
                  alignment="center"
                  visible={visible}
                  onClose={() => setVisible(false)}
                >
                  <NewPlaceTranslation
                    onAccept={() => { }}
                    onClose={() => setVisible(false)}
                  />
                </CModal> */}

                <button
                  disabled={
                    mutating || placeTranslationsToBeDeleted?.ids.length === 0
                  }
                  onClick={handleBulkDelete}
                  className="outline-none  btn btn-outline-danger rounded-pill text-danger d-flex align-items-center gap-2 justify-content-center shadow-primary"
                >
                  {!mutating ? (
                    <>
                      <DeleteIcon fill={colors.primary} /> Supprimer
                    </>
                  ) : (
                    <>
                      <CSpinner size="sm" /> {general.fr.wait}
                    </>
                  )}
                </button>
              </div>
              <div className="search-form">
                <div className=" input-field">
                  <MagnifyingGlassIcon color="gray" width={20} />
                  <input
                    type="text"
                    className="w-50 border-0  outline-none rounded-sm p-1 text-gray fs-6"
                    placeholder="Rechercher"
                    // onChange={e =>
                    //   setSearchQuery(e?.target?.value?.toLowerCase())
                    // }
                    value={searchQuery}
                    onChange={e => {
                      searchHandler(e.target.value.toLowerCase());
                      setSearchQuery(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div
                className="d-flex align-items-center justify-content-center gap-3 bg-gradient-primary p-3"
                style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
              >
                <PartnerSVG />
                <p className="fs-5 fw-bold m-0  text-white text-pre">
                  {" "}
                  {totalCount} {totalCount > 1 ? "Traductions" : "Traduction"}
                </p>
              </div>
            </div>

            <RenderTable
              loading={fetchingTranslations}
              render={() =>
                translations?.length > 0 ? (
                  <PackTranslationsTable
                    packTranslations={translations as PackTranslation[]}
                    packTranslationsToBeDeleted={dispatch}
                  />
                ) : (
                  <p className="text-center fs-5 my-2">{general.fr.noData}</p>
                )
              }
            />
          </CTabPane>
        </CTabContent>
      </BoxWrapper>

      <Pagination
        currentPage={currentPage}
        onPageChange={page => {
          setCurrentPage(page);
        }}
        siblingCount={0}
        totalCount={totalPages}
        pageSize={PAGE_SIZE}
      />
    </AdminRoute>
  );
}
