export const FormMode = {
  CREATE: "create",
  UPDATE: "update"
};

export enum OFFER_TYPES {
  SPECIAL = "SPECIAL_OFFER",
  TOUR = "TOUR_OFFER",
  EVENT = "EVENT_OFFER"
}

export const enum BookingStatus {
  WAITING = "waiting",
  CONFIRMED = "confirmed",
  CANCELED = "canceled",
  REFUSED = "refused",
  PASSED = "passed",
  CASH_PRE_PAYMENT = "cash_pre_payment"
}

export enum BookingStatusDisplayValue {
  WAITING = "En attente",
  CONFIRMED = "Confirmée",
  CANCELED = "Annulée",
  REFUSED = "Refusée",
  PASSED = "Passée",
  CASH_PRE_PAYMENT = "En attente de paiement"
}

export enum BookingTypes {
  PLACE_BOOKING = "PLACE_BOOKING",
  EVENT_BOOKING = "EVENT_BOOKING",
  TOUR_BOOKING = "TOUR_BOOKING",
  SPECIAL_OFFER_BOOKING = "SPECIAL_OFFER_BOOKING",
  PACK_BOOKING = "PACK_BOOKING"
}

export enum DriverProfileStatus {
  IN_REVIEW = "in_review",
  APPROVED = "approved",
  DECLINED = "declined",
  BLOCKED = "blocked"
}

export enum DriverProfileStatusDisplayValue {
  IN_REVIEW = "En attente",
  APPROVED = "Acceptée",
  DECLINED = "Refusée",
  BLOCKED = "Bloquee"
}
