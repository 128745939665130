import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useLocation, useParams } from "react-router-dom";
import BoxWrapper from "../../components/BoxWrapper";
import {
  CFormInput,
  CNav,
  CNavItem,
  CNavLink,
  CSpinner,
  CTabContent,
  CTabPane
} from "@coreui/react";
import apiService from "../../service/apiService";
import { useCookie } from "../../hooks/useCookie";
import { FormSelectAPI } from "../../components/FormReactSelect";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import {
  DriverProfileStatus,
  DriverProfileStatusDisplayValue
} from "../../helpers/enums";
import { useToast } from "../../hooks/useToast";
import { general } from "../../locales/general";
import LeftArrowIcon from "../../components/icons/LeftArrowIcon";

export default function Driver() {
  const params = useParams();
  const { token } = useCookie("vToken");
  const [openViewDoc, setOpenViewDoc] = useState(false);
  const [docIndex, setDocIndex] = useState(-1);
  const [activeTab, setActiveTab] = useState(0);
  const { hash } = useLocation();
  const queryClient = useQueryClient();
  const { toastSuccess, toastError } = useToast();

  const { data } = useQuery<Driver>(["getDriver"], () => {
    return apiService.MakeGetRequest(`users/driver/${params?.driverId}`, token);
  });

  useEffect(() => {
    if (hash) {
      const index = ["#generalInfo", "#documents"].indexOf(hash);
      index > 0 ? setActiveTab(index) : setActiveTab(0);
    }
  }, [hash]);

  const driverDocs = [
    {
      src: data?.driverProfile?.pictureCarteCinRecto,
      label: "Carte CIN Recto"
    },
    {
      src: data?.driverProfile?.pictureCarteCinVerso,
      label: "Carte CIN Verso"
    },
    {
      src: data?.driverProfile?.picturePermisRecto,
      label: "Permis Recto"
    },
    {
      src: data?.driverProfile?.picturePermisVerso,
      label: "Permis Verso"
    },
    {
      src: data?.driverProfile?.picturePermisConfianceRecto,
      label: "Permis Confiance Recto"
    },
    {
      src: data?.driverProfile?.picturePermisConfianceVerso,
      label: "Permis Confiance Verso"
    }
  ].filter(doc => doc.src);

  function getDriverStatusDisplayValue() {
    if (data?.driverProfile?.status === DriverProfileStatus.APPROVED) {
      return DriverProfileStatusDisplayValue.APPROVED;
    } else if (data?.driverProfile?.status === DriverProfileStatus.DECLINED) {
      return DriverProfileStatusDisplayValue.DECLINED;
    } else if (data?.driverProfile?.status === DriverProfileStatus.BLOCKED) {
      return DriverProfileStatusDisplayValue.BLOCKED;
    } else if (data?.driverProfile?.status === DriverProfileStatus.IN_REVIEW) {
      return DriverProfileStatusDisplayValue.IN_REVIEW;
    }
    return DriverProfileStatusDisplayValue.IN_REVIEW;
  }

  const AcceptDriver = useMutation((id: string) => {
    return apiService.MakePutRequest(
      `users/approve-documents/${id}`,
      {},
      token
    );
  });

  const AcceptDriverButton = (id: string) => {
    AcceptDriver.mutate(id, {
      onError(error) {
        toastError(general.fr.message.operationFailed);
      },
      onSuccess() {
        toastSuccess("Chauffeur acceptée");
        queryClient.invalidateQueries(["getDriver"]);
      }
    });
  };

  const RefuseDriver = useMutation((id: string) => {
    return apiService.MakePutRequest(
      `users/decline-documents/${id}`,
      {},
      token
    );
  });

  const RefuseDriverButton = (id: string) => {
    RefuseDriver.mutate(id, {
      onError(error) {
        toastError(general.fr.message.operationFailed);
      },
      onSuccess() {
        toastSuccess("Chauffeur refusée");
        queryClient.invalidateQueries(["getDriver"]);
      }
    });
  };

  return (
    <section>
      <BoxWrapper>
        <CNav variant="tabs" className="mb-2">
          <CNavItem
            href="#generalInfo"
            className={`${activeTab === 0 ? "text-primary" : ""}`}
            active={activeTab === 0}
            onClick={() => setActiveTab(0)}
          >
            <CNavLink className="text-black">Informations Générales</CNavLink>
          </CNavItem>

          <CNavItem
            href="#documents"
            active={activeTab === 1}
            onClick={() => setActiveTab(1)}
          >
            <CNavLink className="text-black">Documents</CNavLink>
          </CNavItem>
        </CNav>
        <CTabContent>
          <CTabPane visible={activeTab === 0}>
            <div
              className="d-flex justify-content-between align-items-baseline"
              id="generalInfo"
            >
              <BoxWrapper>
                <section>
                  <div className="d-flex flex-column align-items-center bg-white px-2 mx-auto ">
                    <form className="col-md-4 form">
                      <div className="d-flex flex-column justify-content-center  py-2 align-items-center">
                        <div className="position-relative">
                          <img
                            src={
                              (data?.profilePicture as string) ??
                              `https://ui-avatars.com/api/?name=${data?.fullName}`
                            }
                            alt=""
                            width={100}
                            height={100}
                            className="rounded-circle border border-2 border-danger overflow-hidden bg-white "
                          />
                        </div>
                      </div>
                    </form>
                    <form className="col-md-10 mt-2">
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label htmlFor="fullName" className="d-block text-sm">
                            Nom complet
                          </label>
                          <CFormInput
                            type="text"
                            className="custom-input w-100 mt-1"
                            name="fullName"
                            placeholder="Nom complet"
                            id="fullName"
                            value={data?.fullName}
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="email" className="d-block text-sm">
                            Email
                          </label>
                          <CFormInput
                            type="email"
                            className="custom-input w-100 mt-1"
                            name="email"
                            placeholder="Email"
                            id="email"
                            value={data?.email}
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="phone" className="d-block text-sm">
                            Téléphone
                          </label>
                          <CFormInput
                            type="number"
                            className="custom-input w-100 mt-1"
                            name="phone"
                            placeholder="Téléphone"
                            id="phone"
                            value={data?.phone}
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="date" className="d-block text-sm">
                            Date de naissance
                          </label>
                          <CFormInput
                            type="date"
                            className="custom-input"
                            placeholder="Date"
                            id="date"
                            name="date"
                            value={
                              data?.birthday
                                ? new Date(data?.birthday)
                                    .toISOString()
                                    .split("T")[0]
                                : ""
                            }
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="cityId" className="d-block text-sm">
                            Ville
                          </label>
                          <FormSelectAPI
                            name="City"
                            id="City"
                            isClearable={false}
                            getOptionLabel={(option: City) => option?.label}
                            getOptionValue={(option: City) => option.id}
                            isOptionSelected={(
                              option: City,
                              selectedValue: City[]
                            ) => {
                              const isSelected =
                                option?.id === selectedValue?.[0]?.id;
                              return isSelected;
                            }}
                            url={{
                              path: "cities"
                            }}
                            value={data?.city}
                            required
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="cityId" className="d-block text-sm">
                            Téléphone verifié
                          </label>
                          <p className="text mt-3 ms-3 text-sm text-secondary ">
                            {data?.isVerifiedPhone ? "Oui" : "Non"}
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </section>
              </BoxWrapper>
            </div>
          </CTabPane>
          <CTabPane visible={activeTab === 1}>
            <BoxWrapper>
              <div className="bg-white px-4 mx-auto">
                <div
                  className="d-flex justify-content-between flex-wrap gap-3 align-items-center py-2"
                  id="documents"
                >
                  <span className="d-flex align-items-baseline gap-2">
                    <Link to={"#generalInfo"} className="py-2 px-1">
                      <LeftArrowIcon
                        className="text-light"
                        width="30"
                        height="30"
                      />
                    </Link>
                    Status:
                    <button
                      className={`btn btn-${
                        data?.driverProfile?.status ===
                        DriverProfileStatus.APPROVED
                          ? "success"
                          : data?.driverProfile?.status ===
                              DriverProfileStatus.DECLINED ||
                            data?.driverProfile?.status ===
                              DriverProfileStatus.BLOCKED
                          ? "danger"
                          : "primary"
                      } shadow-secondary rounded-pill py-2 px-3 text-white`}
                    >
                      {getDriverStatusDisplayValue()}
                    </button>
                  </span>

                  {(data?.driverProfile?.status ===
                    DriverProfileStatus.IN_REVIEW ||
                    data?.driverProfile === null) && (
                    <div className="d-flex align-items-baseline gap-2">
                      <button
                        className="btn btn-primary shadow-secondary rounded-pill py-2 px-3 text-white"
                        onClick={() => AcceptDriverButton(data?.id)}
                        disabled={
                          AcceptDriver.isLoading || driverDocs?.length === 0
                        }
                      >
                        {AcceptDriver.isLoading ? (
                          <CSpinner size="sm" />
                        ) : (
                          " Accepter"
                        )}
                      </button>
                      <button
                        className="btn btn-danger shadow-primary rounded-pill py-2 px-3 text-white"
                        onClick={() => RefuseDriverButton(data?.id)}
                        disabled={
                          RefuseDriver.isLoading || driverDocs?.length === 0
                        }
                      >
                        {RefuseDriver.isLoading ? (
                          <CSpinner size="sm" />
                        ) : (
                          " Refuser"
                        )}
                      </button>
                    </div>
                  )}
                </div>
                <div className="row text-center mt-4">
                  {driverDocs?.length > 0 ? (
                    driverDocs?.map((doc, index) => (
                      <div
                        key={index}
                        className="col-lg-3 col-md-6 mb-4"
                        onClick={() => {
                          setOpenViewDoc(true);
                          setDocIndex(index);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="bg-image ripple shadow-1-strong rounded h-100">
                          <img
                            src={doc?.src}
                            className="w-100 rounded-4 border border-danger"
                            style={{ height: "230px", objectFit: "cover" }}
                            alt={doc?.label}
                          />
                          <p className="mt-2">{doc?.label}</p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-center py-2">Aucun document</p>
                  )}
                </div>
              </div>
            </BoxWrapper>
          </CTabPane>
        </CTabContent>
      </BoxWrapper>
      <Lightbox
        index={docIndex}
        open={openViewDoc}
        carousel={{ finite: true }}
        controller={{ closeOnBackdropClick: true }}
        plugins={[Zoom]}
        zoom={{ maxZoomPixelRatio: 6, doubleClickMaxStops: 3 }}
        close={() => {
          setOpenViewDoc(false);
          setDocIndex(-1);
        }}
        styles={{
          container: { backgroundColor: "rgba(0, 0, 0, .8)" },
          toolbar: { backgroundColor: "rgba(0, 0, 0, 0)" }
          // icon: { height: "40px", width: "40px", fill: "#ffffff" },
        }}
        slides={driverDocs
          ?.filter(doc => doc?.src)
          .map(doc => ({
            src: doc?.src as string
          }))}
      />
    </section>
  );
}
