import React, { useState } from "react";
import Logo from "./icons/Logo";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../context/auth";
import { Bars3BottomRightIcon } from "@heroicons/react/24/outline";
import {
  CCloseButton,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  COffcanvas,
  COffcanvasBody,
  COffcanvasHeader
} from "@coreui/react";
import SideBarMenu from "./SideBarMenu";
import { UserIcon } from "@heroicons/react/24/solid";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/outline";
import { useCookie } from "../hooks/useCookie";
import Sidebar from "./Sidebar";

export default function HeaderMenu() {
  const { user } = useAuthContext();
  const [showMenu, setShowMenu] = useState(false);

  const navigate = useNavigate();
  const { removeCookie } = useCookie("vToken");

  const logout = () => {
    navigate("/");
    removeCookie("vToken");
  };

  return (
    <div className="d-flex w-full m-auto bg-white px-4 py-2 align-items-center justify-content-between HeaderMenu">
      <Link to={"/home"}>
        <Logo width="120" height="45" />
      </Link>
      <CDropdown>
        <CDropdownToggle
          className="rounded-circle border border-2 border-danger overflow-hidden d-none d-lg-block p-2"
          style={{ width: "40px", height: "40px" }}
        >
          <img
            src={user?.profilePicture as string}
            alt=""
            width={30}
            height={30}
          />
        </CDropdownToggle>
        <CDropdownMenu>
          <CDropdownItem className="text-dark mb-1">
            <UserIcon className="w-4 h-4 me-3" />
            <Link to={"/compte"} className="text-black">
              Mon compte
            </Link>
          </CDropdownItem>
          <CDropdownItem className="text-dark mb-1">
            <Cog6ToothIcon className="w-4 h-4 me-3" />
            <Link to={"/compte"} className="text-black">
              Paramètres
            </Link>
          </CDropdownItem>
          <CDropdownItem className="text-dark mb-1" onClick={logout}>
            <ArrowLeftOnRectangleIcon className="w-4 h-4 me-3 text-danger" />
            <Link to={"/compte"} className="text-danger">
              Déconnexion
            </Link>
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>

      <button
        className="d-lg-none btn outline-none border-0"
        onClick={() => setShowMenu(true)}
      >
        <Bars3BottomRightIcon className="w-5 h-5" />
      </button>
      <COffcanvas
        backdrop={true}
        placement="end"
        visible={showMenu}
        onHide={() => setShowMenu(false)}
        className="d-lg-none"
      >
        <COffcanvasHeader>
          <CCloseButton
            className="text-reset"
            onClick={() => setShowMenu(false)}
          />
        </COffcanvasHeader>
        <COffcanvasBody>
          {/* <SideBarMenu className="d-block" closeMenu = {()=>setShowMenu(false)}/> */}
          <Sidebar />
        </COffcanvasBody>
      </COffcanvas>
    </div>
  );
}
