import React from "react";

export default function LogoutIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1139 2H7.07461C4.55496 2 2.5 4 2.5 6.44V17.56C2.5 20.01 4.55496 22 7.09526 22H12.1242C14.6439 22 16.7092 20.01 16.7092 17.57V12.77H10.3068L11.959 14.37C12.2688 14.67 12.2688 15.16 11.959 15.46C11.8041 15.61 11.5976 15.68 11.3911 15.68C11.1949 15.68 10.9883 15.61 10.8334 15.46L7.81811 12.55C7.66322 12.41 7.58061 12.21 7.58061 12C7.58061 11.8 7.66322 11.6 7.81811 11.46L10.8334 8.55C11.1432 8.25 11.6492 8.25 11.959 8.55C12.2688 8.85 12.2688 9.34 11.959 9.64L10.3068 11.23H16.7092V6.45C16.7092 4 14.6439 2 12.1139 2ZM21.5 11.9999C21.5 11.5799 21.1448 11.2299 20.7185 11.2299H16.7095V12.7699H20.7185C21.1448 12.7699 21.5 12.4299 21.5 11.9999Z"
        fill="#FF4D4F"
      />
    </svg>
  );
}
